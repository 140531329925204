import React from 'react';
import { join } from 'path';
import { Link } from 'gatsby';

function LocaleLink({ children, to, ...props }) {
  const path = join('/', to);

  return (
    <Link {...props} to={path}>
      {children}
    </Link>
  );
}

export default LocaleLink;
