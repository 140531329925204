import React from 'react';
import { StaticQuery, graphql, withPrefix } from 'gatsby';
// import { useSiteMetadata } from '../hooks/useSiteMetaData';
import { Helmet } from 'react-helmet';

function SEO({ children, pageDescription, pageTitle, pageUrl, image }) {
  // const { site } = useSiteMetadata();

  return (
    <React.Fragment>
      <StaticQuery
        query={graphql`
          query SiteMetaData {
            site {
              siteMetadata {
                description
                siteUrl
                title
              }
            }
          }
        `}
        render={({ site }) => (
          <React.Fragment>
            <Helmet
              defaultTitle={site.siteMetadata.title}
              htmlAttributes={{ lang: 'en' }}
              titleTemplate={`%s | ${site.siteMetadata.title}`}
            >
              <title>{pageTitle || site.siteMetadata.description}</title>
              <meta
                name="description"
                content={pageDescription || site.siteMetadata.description}
              />

              <meta
                property="og:url"
                content={pageUrl || site.siteMetadata.siteUrl}
              />
              <meta
                property="og:description"
                content={pageDescription || site.siteMetadata.description}
              />
              <meta
                property="og:title"
                content={`${pageTitle || site.siteMetadata.description} | ${
                  site.siteMetadata.title
                }`}
              />
              {image && (
                <meta property="og:image" content={withPrefix(image)} />
              )}

              <meta
                property="twitter:title"
                content={`${pageTitle || site.siteMetadata.description} | ${
                  site.siteMetadata.title
                }`}
              />
              <meta
                property="twitter:card"
                content={image ? 'summary_large_media' : 'summary'}
              />
              {image && (
                <meta property="twitter:image" content={withPrefix(image)} />
              )}
            </Helmet>
            {children}
          </React.Fragment>
        )}
      />
    </React.Fragment>
  );
}

export default SEO;
