import { useStaticQuery, graphql } from 'gatsby';
export const usePageLinks = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query PageLinks {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(src/page-content)/"}, frontmatter: {}}) {
          edges {
            node {
              frontmatter {
                url
                name
                area
                status
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges.map(node => node.node.frontmatter);
};
