import React from 'react';
import { usePageLinks } from '../hooks/usePageLinks';
import LocaleLink from './LocaleLink';

function Footer() {
  const pages = usePageLinks();
  return (
    <div className="container mx-auto px-6">
      <div className="flex flex-col md:flex-row items-center md:justify-between border-t-2 border-gainsboro py-6">
        <div className='flex flex-col md:flex-row items-center md:justify-start'>
        {pages
          .filter((page) => page.area.includes('footer'))
          .filter(page => page.status === 'published')
          .map((page) => (
            <LocaleLink
              to={page.url}
              className="text-lightgray hover:text-slategray hover:bg-gainsboro rounded-full py-2 px-3 font-medium"
            >
              {page.name}
            </LocaleLink>
          ))}
        </div>

        <ul className="inline-flex">
          <li className="my-1">
            <a
              href="https://www.facebook.com/kokasuveniriundavanas"
              target="_blank"
              rel="noopener noreferrer"
              className="text-lightgray hover:text-primary text-sm"
            >
              <svg
                role="img"
                className="fill-current w-6 h-6 mr-3"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" />
              </svg>
            </a>
          </li>
          <li className="my-1">
            <a
              href="http://m.me/kokasuveniriundavanas"
              target="_blank"
              rel="noopener noreferrer"
              className="text-lightgray hover:text-primary text-sm"
            >
              <svg
                role="img"
                className="fill-current w-6 h-6 mr-3"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 11.64C0 4.95 5.24 0 12 0s12 4.95 12 11.64s-5.24 11.64-12 11.64c-1.21 0-2.38-.16-3.47-.46a.96.96 0 0 0-.64.05L5.5 23.92a.96.96 0 0 1-1.35-.85l-.07-2.14a.97.97 0 0 0-.32-.68A11.39 11.39 0 0 1 0 11.64zm8.32-2.19l-3.52 5.6c-.35.53.32 1.14.82.75l3.79-2.87c.26-.2.6-.2.87 0l2.8 2.1c.84.63 2.04.4 2.6-.48l3.52-5.6c.35-.53-.32-1.13-.82-.75l-3.79 2.87c-.25.2-.6.2-.86 0l-2.8-2.1a1.8 1.8 0 0 0-2.61.48z" />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
